import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useAppSelector, useAppDispatch } from "../../store/store";
import Button from "@mui/material/Button";
import { orderSelector, setIndex } from "../../store/slices/getStartedSlice";

const GetStartedTab = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { wizardIndex } = useAppSelector(orderSelector);

    const routeChange = (index) => {
        //dispatch(setIndex(index));
        switch (index) {
            case 0:
                navigate("/");
                break;
            case 1:
                navigate("/company");
                break;
            case 2:
                navigate("/payment");
                break;
        }
    };

    return (
        <>
            <div className="bg-[#dcebfe] rounded flex items-center px-1 mb-3 gap-2 p-3">
                <div className="w-10 h-10">
                  <img src="../icon.png" alt="icon" className="w-full h-full object-contain" />
                </div>
                <p>
                    During signup, if you need assistance or have questions,
                    please click the chat support icon in the bottom right
                    corner and one of our team will assist you.
                </p>
            </div>
            <ul class="text-sm  font-medium text-center text-gray-500 sm:flex">
                <li class="w-full px-1 py-1 h-[50px]">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={wizardIndex != 0}
                        className={
                            wizardIndex == 0
                                ? "bg-gray-300 w-full h-full text-black rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                                : "bg-white w-full h-full text-black rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                        }
                    >
                        Details
                    </Button>
                </li>
                <li class="w-full px-1 py-1 h-[50px]">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={wizardIndex != 1}
                        className={
                            wizardIndex == 1
                                ? "bg-gray-300 w-full h-full text-black rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                                : "bg-white w-full h-full text-black rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                        }
                    >
                        Prefix
                    </Button>
                </li>
                <li class="w-full px-1 py-1 h-[50px]">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={wizardIndex != 2}
                        className={
                            wizardIndex == 2
                                ? "bg-gray-300  w-full h-full text-black rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                                : "bg-white w-full h-full text-black rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                        }
                    >
                        Review
                    </Button>
                </li>
                <li class="w-full px-1 py-1 h-[50px]">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={wizardIndex != 3}
                        className={
                            wizardIndex == 3
                                ? "bg-gray-300 w-full h-full text-black rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                                : "bg-white w-full h-full text-black rounded-md px-4 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                        }
                    >
                        Payment
                    </Button>
                </li>
            </ul>
        </>
    );
};

export default GetStartedTab;
