import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import SideMenuAdmin from "./SideMenuAdmin";
import SideMenuRetailer from "./SideMenuRetailer";
import SideMenuServiceProvider from "./SideMenuServiceProvider";
import SideMenuSupplier from "./SideMenuSupplier";
import SideMenuItem from "./SideMenuItem";

const SideMenu = () => {
  const userAuth = useAuthContext();
  const user = userAuth.user;

  const logout = async () => userAuth.logoutUser();

  const getMenuItems = () => {
    return (
      <div className="items">
        {
          <>
            <SideMenuItem>
              <Link to="/DashboardHome" className="!text-white pb-4" style={{
                cursor: "pointer",
                textDecoration: "underline",
              }}>DASHBOARD</Link>
            </SideMenuItem>
            {getMenuToDisplay()}
          </>
        }
      </div>
    );
  };

  const isUserAdmin =
    !user || user.isAdmin || user.isSuperUser || user.isEmployee;

  const getMenuToDisplay = () => {
    if (!user) {
      return null;
    }

    if (isUserAdmin) {
      return <SideMenuAdmin />;
    }

    if (userAuth.isUserSupplier()) {
      return <SideMenuSupplier />;
    }

    if (userAuth.isUserRetailer()) {
      return <SideMenuRetailer />;
    }

    if (userAuth.isUserServiceProvider()) {
      return <SideMenuServiceProvider />;
    }

    return null;
  };

  

  return (
    <div className="side-menu">
      <img
        className="logo"
        src="/BBA-Logo-Stack-TW.png"
        style={{ objectFit: "contain" }}
      />

      <div className="items-container">{user && getMenuItems()}</div>

      <div className="footer">
        <ul className="ml-2">
          {user && (
            <li
              onClick={logout}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              LOGOUT
            </li>
          )}
          {/* {!userAuth.isUserSupplier() &&
          <>
          <li>FAQ</li>
          <li>Contact Us</li>
          </>} */}

        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
