import React from 'react';
import { Link } from 'react-router-dom';
import SideMenuGPC from './SideMenuGPC';
import SideMenuItem from './SideMenuItem';

const SideMenuSupplier = () => {

  return (
    <>
    </>
  );
};

export default SideMenuSupplier;