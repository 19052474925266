import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  addForm,
  orderSelector,
  fetchCountry,
  fetchState,
  fetchNumberOfEmployees,
  fetchAnnualTurnover,
  fetchIndustries,
  fetchAccountDetails,
  submitAccountDetails,
  setIndex
} from "../../store/slices/getStartedSlice";
import { useAuthContext } from '../../context/AuthContext';
import GetStartedTab from "./GetStartedTab";

const schema = yup.object().shape({
  first_name: yup
    .string()
    .required("First Name is required")
    .min(2, "First name should be at least 2 characters")
    .max(50, "First name should be at most 50 characters"),

  last_name: yup
    .string()
    .required("Last Name is required")
    .min(2, "Last name should be at least 2 characters")
    .max(50, "Last name should be at most 50 characters"),

  prefered_name: yup
    .string()
    .nullable()
    .max(50, "Prefered Name should be at most 50 characters"),

  position: yup
    .string()
    .nullable()
    .max(50, "position should be at most 50 characters"),

  annual_turnover: yup.string().required("Annual turnover is required"),

  number_of_employees: yup.string().required("number of employees is required"),

  company_name: yup
    .string()
    .required("Company Name is required")
    .min(2, "Company name should be at least 2 characters")
    .max(50, "Company name should be at most 50 characters"),

  trading_name: yup
    .string()
    .required("Trading Name is required")
    .min(2, "Trading name should be at least 2 characters")
    .max(50, "Trading name should be at most 50 characters"),

    company_abn: yup
    .string()
    .test('is-valid-abn', 'Invalid ABN format must be 11 digits', function (value) {
      // Remove spaces
      const cleanedValue = (value || "").replace(/\s+/g, '');

      // Check if the value has exactly 11 digits
      if (!/^\d{11}$/.test(cleanedValue)) {
        return false;
      }

      return true;
    }),

  website: yup
    .string()
    .nullable(),

  industry: yup.string().required("Industry is required, select OTHER if not in list"),

  other: yup.string().nullable().when('industry', {
    is: '1000006',
    then: () => yup.string().required('Other is required').max(50, "other should be at most 50 characters"),
  }),

  company_address_1: yup
    .string()
    .required("Address is required")
    .min(2, "Address should be at least 2 characters")
    .max(100, "Address should be at most 50 characters"),

  company_address_2: yup
    .string()
    .nullable()
    .max(100, "Address 2 should be at most 50 characters"),

  postcode: yup
    .string()
    .required("Postcode is required")
    .min(4, "Postcode should be at least 4 characters")
    .max(7, "Postcode should be at most 7 characters"),

  city: yup
    .string()
    .required("City is required")
    .min(2, "City should be at least 2 characters")
    .max(50, "City should be at most 50 characters"),

  country_id: yup.string().required("Country is required"),

  state: yup.string().required("State is required"),

  mobile_number: yup.string().nullable().test('is-valid-number', 'Phone number must start with 0 or 1 and have 10 digits', function(value) {
    if (!value) return true;  // Allow empty or null

    return /^[01]\d{9}$/.test(value);
  }),

  direct_landline_number: yup.string().nullable().test('is-valid-number', 'Phone number must start with 0 or 1 and have 10 digits', function(value) {
    if (!value) return true;  // Allow empty or null

    return /^[01]\d{9}$/.test(value);
  }),

  company_phone_number: yup.string().nullable().test('is-valid-number', 'Phone number must start with 0 or 1 and have 10 digits', function(value) {
    if (!value) return true;  // Allow empty or null

    return /^[01]\d{9}$/.test(value);
  }),

})
.test('at-least-one-number', 'Please provide at least one contact number', function(values) {
  const { direct_landline_number, company_phone_number, mobile_number } = values;
  if (!direct_landline_number && !company_phone_number && !mobile_number) {
    return this.createError({ message: 'Please provide at least one contact number' });
  }
  return true;
});

const GetStartedAccountDetails = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userAuth = useAuthContext();
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const user = userAuth.user;
  const { form, country, state, numberOfEmployees, annualTurnover, industries } = useAppSelector(orderSelector);
  const { t } = useTranslation(["common"]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const json = await dispatch(submitAccountDetails(form));
    if(json.status==204){
      dispatch(setIndex(1));
      navigate("/GetStarted/AccountPrefix");
    }
    
  };

  const setFormField = (key, value) => {
    let data = Object.assign({}, form);
    data[key] = value;
    dispatch(addForm(data));
    if (key == "country_id") {
      dispatch(fetchState(value));
    }
  };
  
  const fetchUpdatedForm = async () => {
    let data = Object.assign({}, form);
    if(!isFormLoaded){
        for (const [key, value] of Object.entries(data)) {
          setValue(key, value);
        }
        if(Object.keys(data).length>20){
          setIsFormLoaded(true);
        }
    }
    

    if(form?.country_id && (state || []).length==0){
      dispatch(fetchState(form?.country_id));
    }

    
    
  }

  useEffect(()=>{
    
    fetchUpdatedForm();
  }, [form])

  useEffect(() => {
    if(user?.hasSignedUp){
      navigate("/DashboardHome");
    }
    fetchCountryData();
  }, []);

  

  const fetchCountryData = async () => {
    await dispatch(fetchCountry());
    let data = Object.assign({}, form);
    data = {
      ...data,
      country_id:process.env.REACT_APP_SELECTED_COUNTRY_ID,
      account_type:"supplier"

    }
    dispatch(addForm(data));
    if(process.env.REACT_APP_SELECTED_COUNTRY_ID){
      dispatch(fetchState(process.env.REACT_APP_SELECTED_COUNTRY_ID));
    }
    await dispatch(fetchNumberOfEmployees());
    await dispatch(fetchAnnualTurnover());
    await dispatch(fetchIndustries());
    await dispatch(fetchAccountDetails());
    
  }  

  return (
    <div className="w-full h-full flex-grow overflow-auto py-10 px-5">
      {/* Navigation component */}
      <GetStartedTab />
      {/* Form */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-1 py-3 mt-10">
          <div className="border p-4 border-black">
            <h1 className="font-bold text-lg">{t("common:heading_contact_person").toString()}</h1>
            <div className="gap-5 grid lg:grid-cols-2 grid-cols-1 mt-10">
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* First Name */}
                <Box htmlFor="first_name" sx={{ color: 'primary.main' }}>{t("common:first_name").toString()}</Box>
                <input
                  {...register("first_name")}
                  onChange={(e) => setFormField("first_name", e.target.value)}
                  type="text"
                  value={form?.first_name || ""}
                  id="first_name"
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {/* Display validation errors */}
                {errors.first_name && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.first_name?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Last Name */}
                <Box htmlFor="last_name" sx={{ color: 'primary.main' }}>{t("common:last_name").toString()}</Box>
                <input
                  {...register("last_name")}
                  onChange={(e) => setFormField("last_name", e.target.value)}
                  type="text"
                  value={form?.last_name || ""}
                  id="last_name"
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {/* Display validation errors */}
                {errors.last_name && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.last_name?.message}</Box>

                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Last Name */}
                <Box htmlFor="prefered_name" sx={{ color: 'primary.main' }}>{t("common:prefered_name").toString()}</Box>
                <input
                  {...register("prefered_name")}
                  type="text"
                  id="prefered_name"
                  value={form?.prefered_name || ""}
                  onChange={(e) => setFormField("prefered_name", e.target.value)}
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {errors.prefered_name && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.prefered_name?.message}</Box>
                )}
                
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Position */}
                <Box htmlFor="position" sx={{ color: 'primary.main' }}>{t("common:position").toString()}</Box>
                <input
                  {...register("position")}
                  onChange={(e) => setFormField("position", e.target.value)}
                  type="text"
                  value={form?.position || ""}
                  id="position"
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {/* Display validation errors */}
                {errors.position && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.position?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Last Name */}
                <Box htmlFor="direct_line" sx={{ color: 'primary.main' }}>{t("common:direct_line").toString()}</Box>
                <input
                  {...register("direct_landline_number")}
                  value={form?.direct_landline_number || ""}
                  onChange={(e) => setFormField("direct_landline_number", e.target.value)}
                  type="text"
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {errors.direct_landline_number && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.direct_landline_number?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Mobile Number */}
                <Box htmlFor="mobile_number" sx={{ color: 'primary.main' }}>{t("common:mobile_number").toString()}</Box>
                <input
                  {...register("mobile_number")}
                  onChange={(e) => setFormField("mobile_number", e.target.value)}
                  type="text"
                  //value={form?.mobile_number || ""}
                  id="number"
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {/* Display validation errors */}
                {errors.mobile_number && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.mobile_number?.message}</Box>
                )}
              </div>
            </div>
          </div>
          {errors?.[""]?.message && (
            <Box className="text-sm mt-2 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors?.[""]?.message}</Box>
          )}
          <div className="border p-4 border-black mt-10">
            <h1 className="font-bold text-lg">{t("common:heading_company_information").toString()} </h1>
            <div className="gap-5 grid lg:grid-cols-2 grid-cols-1 mt-10">
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Account type */}
                <Box htmlFor="company_name" sx={{ color: 'primary.main' }}>{t("common:company_name").toString()}</Box>
                <input
                  {...register("company_name", {
                    required: "company name is required",
                  })}
                  onChange={(e) => setFormField("company_name", e.target.value)}
                  type="text"
                  value={form?.company_name || ""}
                  id="company_name"
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {/* Display validation errors */}
                {errors.company_name && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_name?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Trading namee */}
                <Box htmlFor="trading_name" sx={{ color: 'primary.main' }}>{t("common:trading_name").toString()}</Box>
                <input
                  {...register("trading_name", {
                    required: "trading name is required",
                  })}
                  onChange={(e) => setFormField("trading_name", e.target.value)}
                  type="text"
                  value={form?.trading_name || ""}
                  id="trading_name"
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {/* Display validation errors */}
                {errors.trading_name && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.trading_name?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Company abn */}
                <Box htmlFor="company_abn" sx={{ color: 'primary.main' }}>{t("common:company_abn").toString()}</Box>
                <input
                  {...register("company_abn", {
                    required: "company abn is required",
                  })}
                  onChange={(e) => setFormField("company_abn", e.target.value)}
                  type="text"
                  value={form?.company_abn || ""}
                  id="companyabn"
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {/* Display validation errors */}
                {errors.company_abn && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_abn?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Account type */}
                <Box htmlFor="number_of_employees" sx={{ color: 'primary.main' }}>{t("common:number_of_employees").toString()}</Box>
                <select
                  {...register("number_of_employees")}
                  onChange={(e) => setFormField("number_of_employees", e.target.value)}
                  value={form?.number_of_employees || ""}
                  className={`rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm form-check-input `}
                >
                  {(numberOfEmployees || []).map((item, index)=>(
                    <option key={index} value={item.SystemSelectID}>{item.SelectionName}</option>
                  ))}
                </select>
                {/* Display validation errors */}
                {errors.number_of_employees && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.number_of_employees?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Account type */}
                <Box htmlFor="account_type" sx={{ color: 'primary.main' }}>{t("common:account_type").toString()}</Box>
                <select
                  {...register("account_type")}
                  onChange={(e) => setFormField("account_type", e.target.value)}
                  value={form?.account_type || ""}
                  disabled
                  className={`rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm form-check-input `}
                >
                  <option selected={"supplier" === form.account_type} value="supplier">Supplier</option>
                  <option selected={"buyer" === form.account_type} value="buyer">Buyer</option>
                </select>
                {/* Display validation errors */}
                {errors.account_type && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.account_type?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Account type */}
                <Box htmlFor="turnover" sx={{ color: 'primary.main' }}>{t("common:annual_turnover").toString()}</Box>
                <select
                {...register("annual_turnover")}
                onChange={(e) => setFormField("annual_turnover", e.target.value)}
                  value={form?.annual_turnover || ""}
                  className={`rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm form-check-input `}
                >
                  {(annualTurnover || []).map((item, index)=>(
                    <option key={index} value={item.SystemSelectID}>{item.SelectionName}</option>
                  ))}
                </select>
                {errors.annual_turnover && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.annual_turnover?.message}</Box>
                )}
              </div>
              
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* industry */}
                <Box htmlFor="industry" sx={{ color: 'primary.main' }}>{t("common:industry").toString()}</Box>
                <select
                  {...register("industry")}
                  onChange={(e) => setFormField("industry", e.target.value)}
                  value={form?.industry || ""}
                  className="rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                >
                  {(industries || []).map((item, index)=>(
                    <option key={index} value={item.SystemSelectID}>{item.SelectionName}</option>
                  ))}
                </select>
                {/* Display validation errors */}
                {errors.industry && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.industry?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* other */}
                <Box htmlFor="other" sx={{ color: 'primary.main' }}>{t("common:other").toString()}</Box>
                <input
                  {...register("other")}
                  onChange={(e) => setFormField("other", e.target.value)}
                  type="text"
                  value={form?.other || ""}
                  disabled={((industries || []).filter(i=>i.SystemSelectID==form?.industry)?.[0] || {})?.SelectionName!="-- OTHER --"}
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {/* Display validation errors */}
                {errors.other && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.other?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Company phone number */}
                <Box htmlFor="company_phone_number" sx={{ color: 'primary.main' }}>{t("common:company_phone_number").toString()}</Box>
                <input
                  {...register("company_phone_number", {
                    required: "phone number is required",
                  })}
                  onChange={(e) =>
                    setFormField("company_phone_number", e.target.value)
                  }
                  type="text"
                  value={form?.company_phone_number || ""}
                  id="company_phone_number"
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {/* Display validation errors */}
                {errors.company_phone_number && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_phone_number?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Company address 1 */}
                <Box htmlFor="Website" sx={{ color: 'primary.main' }}>{t("common:website").toString()}</Box>
                <input
                  {...register("website", {
                    required: "Website is required",
                  })}
                  type="text"
                  onChange={(e) =>
                    setFormField("website", e.target.value)
                  }
                  id="Website"
                  value={form?.website || ""}
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {errors.website && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.website?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Company address 1 */}
                <Box htmlFor="company_address_1" sx={{ color: 'primary.main' }}>{t("common:company_address_1").toString()}</Box>
                <input
                  {...register("company_address_1", {
                    required: "address 1 is required",
                  })}
                  onChange={(e) =>
                    setFormField("company_address_1", e.target.value)
                  }
                  type="text"
                  value={form?.company_address_1 || ""}
                  id="company_address_1"
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {/* Display validation errors */}
                {errors.company_address_1 && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_address_1?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Company address 2 */}
                <Box htmlFor="company_address_2" sx={{ color: 'primary.main' }}>{t("common:company_address_2").toString()}</Box>
                <input
                  {...register("company_address_2", {
                    required: "address 2 is required",
                  })}
                  onChange={(e) =>
                    setFormField("company_address_2", e.target.value)
                  }
                  type="text"
                  value={form?.company_address_2 || ""}
                  id="company_address_2"
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {/* Display validation errors */}
                {errors.company_address_2 && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_address_2?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* city */}
                <Box htmlFor="city" sx={{ color: 'primary.main' }}>{t("common:city").toString()}</Box>
                <input
                  {...register("city", { required: "city is required" })}
                  onChange={(e) => setFormField("city", e.target.value)}
                  type="text"
                  value={form?.city || ""}
                  id="city"
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {/* Display validation errors */}
                {errors.city && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.city?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* state */}
                <Box htmlFor="state" sx={{ color: 'primary.main' }}>{t("common:state").toString()}</Box>
                <select
                  {...register("state")}
                  value={form?.state || ""}
                  onChange={(e) => setFormField("state", e.target.value)}
                  className="rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                >
                  <option value="">Select State</option>
                  {(state || []).map((item, index) => (
                    <option key={index} value={item.StateName}>
                      {item.StateName}
                    </option>
                  ))}
                </select>
                {/* Display validation errors */}
                {errors.state && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.state?.message}</Box>
                )}
              </div>
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* Postcode */}
                <Box htmlFor="postcode" sx={{ color: 'primary.main' }}>{t("common:postcode").toString()}</Box>
                <input
                  {...register("postcode", { required: "postcode is required" })}
                  onChange={(e) => setFormField("postcode", e.target.value)}
                  type="text"
                  value={form?.postcode || ""}
                  id="postcode"
                  className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                />
                {/* Display validation errors */}
                {errors.postcode && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.postcode?.message}</Box>
                )}
              </div>
              
              {/* Input fields with labels */}
              <div className="flex gap-3 flex-col">
                {/* country */}
                <Box htmlFor="country" sx={{ color: 'primary.main' }}>{t("common:country").toString()}</Box>
                <select
                  {...register("country_id")}
                  onChange={(e) => setFormField("country_id", e.target.value)}
                  value={form?.country_id || ""}
                  //id={"country_id"}
                  className="rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
                >
                  {(country || []).map((item, index) => (
                    <option key={index} value={item.CountryCode} selected={item.CountryCode === form.country_id}>
                      {item.CountryName}
                    </option>
                  ))}
                </select>
                {/* Display validation errors */}
                {errors.country_id && (
                  <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.country_id?.message}</Box>
                )}
              </div>
              
            </div>
          </div>
          {/* Next button  */}
          <div className="mt-10">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="px-8 py-2 bg-gray-300 text-black rounded-md border border-gray-600"
            >
              {t("common:next").toString()}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GetStartedAccountDetails;
