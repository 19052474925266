import { getToken } from "../provider/AuthProvider";
import { getAuthRequest } from "./API";

const listCountries = async () => {
  try {
    return await getAuthRequest(getToken()).get('getstarted/listCountries');
  } catch (error) {
    return error;
  }
};

const listselection = async (data) => {
  try {
    return await getAuthRequest(getToken()).post('systemselect/listselection', data);
  } catch (error) {
    return error;
  }
};

const listStates = async (countryCode) => {
  try {
    return await getAuthRequest(getToken()).get(`getstarted/listStates/${countryCode}`);
  } catch (error) {
    return error;
  }
};

const listClientSecret = async (data) => {
  try {
    return await getAuthRequest(getToken()).post(`getstarted/stripeClientSecret`, data);
  } catch (error) {
    return error;
  }
};

const getAccountContactDetails = async () => {
  try {
    return await getAuthRequest(getToken()).get('getstarted/getAccountContactDetails');
  } catch (error) {
    return error;
  }
  
};

const updateAccountContactDetail = async (data) => {
  try {
    return await getAuthRequest(getToken()).post('getstarted/updateAccountContactDetail', data);
  } catch (error) {
    return error;
  }

};

const updateAccountReview = async () => {
  try {
    return await getAuthRequest(getToken()).post('getstarted/updateAccountReview');
  } catch (error) {
    return error;
  }

};

const updateAccountContactDetails = async (data) => {
  try {
    return await getAuthRequest(getToken()).post('getstarted/updateAccountContactDetails', data);
  } catch (error) {
    return error;
  }

};

const confirmTermsConditions = async () => {
  try {
    return await getAuthRequest(getToken()).post('getstarted/confirmTermsConditions');
  } catch (error) {
    return error;
  }
};

const getCompanyToBeClaimed = async () => {
  try {
    return await getAuthRequest(getToken()).get('getstarted/getCompanyToBeClaimed');
  } catch (error) {
    return false;
  }
};

const setAccountAsSupplier = async () => {
  try {
    return await getAuthRequest(getToken()).post('getstarted/setAccountAsSupplier');
  } catch (error) {
    return false;
  }
};

const setAccountAsRetailer = async () => {
  try {
    return await getAuthRequest(getToken()).post('getstarted/setAccountAsRetailer');
  } catch (error) {
    return false;
  }
};

const setAccountAsServiceProvider = async () => {
  try {
    return await getAuthRequest(getToken()).post('getstarted/setAccountAsServiceProvider');
  } catch (error) {
    return false;
  }
};

const claimCompany = async (data) => {
  try {
    return await getAuthRequest(getToken()).post('getstarted/claimCompany',data);
  } catch (error) {
    return error;
  }
};

const searchCompanyByGTIN = async (GTIN) => {
  try {
    return await getAuthRequest(getToken()).get('getstarted/searchCompanyByGTIN/' + GTIN);
  } catch (error) {
    return error;
  }
};

const listRetailerIndustries = async () => {
  try {
    return await getAuthRequest(getToken()).get('getstarted/listRetailerIndustries');
  } catch (error) {
    return false;
  }
};

const listServiceProviderIndustries = async () => {
  try {
    return await getAuthRequest(getToken()).get('getstarted/listServiceProviderIndustries');
  } catch (error) {
    return false;
  }
};

const listSupplierIndustries = async () => {
  try {
    return await getAuthRequest(getToken()).get('getstarted/listSupplierIndustries');
  } catch (error) {
    return false;
  }
};

const listTradingGroup = async () => {
  try {
    return await getAuthRequest(getToken()).get('getstarted/listTradingGroup');
  } catch (error) {
    return false;
  }
};

const createTradingGroup = async (GroupName, GroupType) => {
  try {
    return await getAuthRequest(getToken()).post('getstarted/createTradingGroup', { GroupName, GroupType });
  } catch (error) {
    return false;
  }
};

const assignToTradingGroup = async (GroupID) => {
  try {
    return await getAuthRequest(getToken()).post('getstarted/assignToTradingGroup', { GroupID });
  } catch (error) {
    return false;
  }
};

const investorExpressionInterest = async (Name, Email, Amount, Questions) => {
  try {
    return await getAuthRequest(getToken()).post('investor/defaultinterest', { Name, Email, Amount, Questions });
  } catch (error) {
    return false;
  }
};

const getCouponCode = async (CouponCode) => {
  try {
    return await getAuthRequest(getToken()).post('tab/CPNG', { CouponCode });
  } catch (error) {
    return error.response;
  }
};

const updateCouponCode = async (data) => {
  try {
    return await getAuthRequest(getToken()).post('tab/CPNP', data);
  } catch (error) {
    return error.response;
  }
};

export {
  listCountries, listselection, listStates, listClientSecret, getAccountContactDetails, investorExpressionInterest,
  updateAccountContactDetails, updateAccountContactDetail, updateAccountReview, confirmTermsConditions, getCompanyToBeClaimed,
  setAccountAsSupplier, setAccountAsRetailer, setAccountAsServiceProvider,
  claimCompany, searchCompanyByGTIN, listRetailerIndustries, listServiceProviderIndustries, listSupplierIndustries,
  listTradingGroup, createTradingGroup, assignToTradingGroup,getCouponCode,updateCouponCode
};