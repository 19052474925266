import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useAppSelector, useAppDispatch } from "../../store/store";
import { useTheme } from "@mui/material/styles";
import {
  orderSelector,
  submitOrder,
  setIndex,
  submitAccountPayment,
} from "../../store/slices/getStartedSlice";
import GetStartedStripePaymentElement from "./GetStartedStripePaymentElement";
import Button from "@mui/material/Button";
import { useAuthContext } from "../../context/AuthContext";
import {updateCouponCode} from '../../api/SignUp';


const Payment = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userAuth = useAuthContext();
  const user = userAuth.user;

  const {
    form,
    wizardIndex,
    isContactValidate,
    isShippingValidate,
    isPaymentValidate,
    country,
  } = useAppSelector(orderSelector);

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [open, setOpen] = useState(false);

  const logout = async () => userAuth.logoutUser();

  const { t } = useTranslation(["common"]);

  const selectedCountry =
    (country || []).filter((i) => i.CountryCode == form?.country_id)?.[0] || {};

  React.useEffect(() => {
    if (wizardIndex < 2) {
      navigate("/");
    }
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, [wizardIndex]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setMessage("");

    if (!form?.termCondition) {
      setMessage(t("common:accept_term_condition").toString());
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }
    setIsProcessing(true);

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: form?.company_name,
            phone:
              form?.direct_landline_number ??
              form?.mobile_number ??
              form?.company_phone_number,
            address: {
              country: selectedCountry?.CountryCodeAN2 || "AU",
              postal_code: form?.postcode,
              state: form?.state || "Australian Capital Territory",
              city: form?.city,
              line1: form?.company_address_1,
              line2: form?.company_address_2 ?? "~",
            },
          },
        },
      },
      redirect: "if_required",
    });

    if (response?.error) {
      dispatch(setIndex(3));
      navigate("/GetStarted/AccountPayment");
      setMessage(response.error.message);
    } else if (response?.paymentIntent?.id) {
      let data = form;
      data = {
        ...data,
        transaction_id: response.paymentIntent.id,
      };
      if(form?.coupon?.CouponID){
        updateCouponCode({CouponID:form?.coupon?.CouponID, QuantityUsed:parseInt(form?.coupon?.QuantityUsed)+1});
      }
     
      const json = await dispatch(submitOrder(data));

      if (json.status == 204) {
        userAuth.setUser({
          ...user,
          hasSignedUp: true,
          isApprovalBeingProcessed: true,
          isSupplier: true,
        });

        const timer = setTimeout(() => {
          logout();
        }, 2000);
        return () => clearTimeout(timer);

       
      }

      //display success message or redirect user
    }

    setIsProcessing(false);
  };

  const back = () => {
    dispatch(setIndex(2));
    navigate("/GetStarted/AccountReview");
  };


  return (
    <>
      {Object.keys(form).length === 0 ? (
        <div className={`w-full h-full flex-grow overflow-auto py-10 px-5`}>
          <div className="w-80 h-80 mx-auto">
            <img
              src="https://members.bringback.app/BBA-Logo-TBl.png"
              alt="logo"
              className="w-full h-full object-contain object-centers"
            />
          </div>
          <div
            className="p-4 text-sm text-center text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
            role="alert"
          >
            <span className="font-semibold">Success:</span>{" "}
            {t("common:order_success_meessage").toString()}
          </div>
          <div className="flex flex-col items-center gap-3 mt-10">
            <span>
              Thank you for signing up. Please download the app and test on your
              products. And then test on your competitor products
            </span>
            <Button
              variant="contained"
              color="primary"
              href="https://bringbackaustralia.app/download-app/"
              className="px-8 py-2 w-max bg-gray-300 text-black rounded-md border border-gray-600"
            >
              Download our APP
            </Button>
          </div>
        </div>
      ) : (
        <>
            <div
              className={`w-full h-full flex-grow overflow-auto py-10 px-5`}
            >
             
              {!!message && (
                <div
                  className="p-4 mb-4 mt-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                  role="alert"
                  >
                  {message}
                </div>
              )}
              {
                !open && 
                <>
                  <div className="px-4 py-2 mt-4 gap-5 grid lg:grid-cols-2 grid-cols-1">
                    <div className={`block`}>
                      <GetStartedStripePaymentElement />
                    </div>
                  </div>
                  <div className="flex items-center ml-4 gap-4">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={back}
                      className="px-8 py-2 bg-gray-300 text-black rounded-md border border-gray-600"
                    >
                      {t("common:back_button_text").toString()}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        isProcessing ||
                        !isContactValidate ||
                        !isShippingValidate ||
                        !isPaymentValidate
                      }
                      onClick={handleSubmit}
                      className="px-8 py-2 bg-gray-300 text-black rounded-md border border-gray-600"
                    >
                      {isProcessing
                        ? t("common:processing").toString()
                        : t("common:submit_text").toString()}
                    </Button>
                  </div>
                </>
              }
            </div>
            
        </>
      )}
    </>
  );
};

export default Payment;
